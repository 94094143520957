.box-modal{
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.808);
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 200001;
    overflow-y: none;
}
.box-modal .content .header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e9e9e9;
    padding-bottom: 20px;
}
.box-modal .content .header button{
    background-color: transparent;
    border: none;
    cursor: pointer;
    opacity: 0.5;
    outline: none;
}
.box-modal .content .header button:hover{
    opacity: 1;
}
.box-modal .content{
    display: flex;
    flex-direction: column;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 5px;
    max-width: 40%;
    max-height: 80%;
    overflow-y: auto;
}
.box-modal .content .body img{
    width: 100%;
}
.box-modal .content b{
    font-size: 12px;
}
.box-modal .content p{
    margin-top: 20px;
}