.section-loader{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    position: absolute;
    z-index: 20000;
    background-color:#009DE1;
}


.loader-spin {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #009DE1;
    width: 35px;
    height: 35px;
    -webkit-animation: spin 500ms linear infinite; /* Safari */
    animation: spin 500ms linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }