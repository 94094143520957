.footer{
    display: flex;
    flex: 1;
    background-color: #343434;
    padding: 50px 0;
}
.footer-corpy{
    display: flex;
    flex: 1;
    background-color: #000;
    padding: 30px 0;
}
.footer-corpy, .container p{
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 0.8em;
}
.footer .container{
    display: flex;
    flex-direction: row;
    width: 100%;
} 
.section-sobre{
    display: flex;
    width: 100%;
    justify-content: flex-start;
}
.section-sobre content{
    max-width: 300px;
}
.section-sobre content img{
    margin-bottom: 10px;
}
.section-sobre content a{
    color: #fff;
    text-decoration: none;
    font-size: 12px;
}
.section-apio{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
}
.section-apio h2{
    font-size: 15px;
    color: #fff;
    margin-bottom: 10px;
}
.section-apio img{
    width: 300px;
}