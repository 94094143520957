section{
    width: 100%;
    z-index: 200;
    position: fixed;
}
header .container{
    height: 130px;
    /* background-color: #009DE1; */
    background: rgb(31,69,133);
    background: linear-gradient(0deg, rgba(31,69,133,1) 0%, rgba(57,128,244,1) 100%);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

header .container .box-bg-efect-menu{
    flex: 1;
    height: 130px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: url('../../imgs/FundoTechnology.png');
    background-repeat: no-repeat;
    background-position-x: 0px;
    background-position-y: -200px;
}

header .container .box-bg-efect-menu .title-menu{
    color: #fff;
    font-size: 18px;
}

header img{ max-height: 75px;}

header .menu ul{
    list-style: none;
    display: flex;
    flex-direction: row;
}
header .menu ul li{
    margin-left: 20px;
}
header .menu ul li a{
    text-decoration: none;
    font-weight: 700;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 15px;
    transition: ease 100ms;
}
header .menu ul li a:hover{
    color: #343434;
}
header .menu ul li .active{
    color: #343434;
}
.menu-icon{
    padding-top: 10px;
    display: none;
    cursor: pointer;
}

.hide-menu{ display: none;}

header .menu-mobile ul{
    list-style: none;
    display: flex;
    flex-direction: row;
}
header .menu-mobile ul li{
    margin-left: 20px;
}
header .menu-mobile ul li a{
    text-decoration: none;
    font-weight: 700;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 15px;
    transition: ease 100ms;
}
header .menu-mobile ul li a:hover{
    color: #343434;
}
header .menu-mobile ul li .active{
    color: #343434;
}

/*Responsive*/
@media screen and (max-width: 930px) {
    .menu-icon{ display: initial;}
    .menu{ display: none;}
    .menu-mobile-icon{
        display: initial;
    }
    
    header .container .box-bg-efect-menu{
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    header .container .box-bg-efect-menu .title-menu{
        padding-bottom: 20px;
    }

    .section-logo-menu{
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    header img{ margin-top: 11px;}
    .menu-mobile{
        width: 100%;
        background-color: rgba(17, 17, 17, 0.973);
        margin-top: 126px;
        position: absolute;
        padding: 25px;
        z-index: 2000!important;
    }
    .menu-mobile ul{
        flex: 1;
        flex-direction: column!important;
        align-items: center;
    }
    .menu-mobile ul li{ padding: 10px 0;}
    header .menu-mobile ul li .active{
        color: #009DE1;
    }
}


