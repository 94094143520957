.container-page-sobre{
    min-height: 100px;
    padding-top: 60px!important;
}
.header-page-sobre{
    padding-top: 130px;
    background-image: url('../../imgs/bg1.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.header-page-sobre content{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.39);
    width: 100%;
    height: 300px;
}
.header-page-sobre content h2{
    color: #ffffff;
}