.container-pag-videos{
    display: flex;
    flex: 1;
    justify-content: space-around;
    flex-direction: column;
    margin-top: 20px;
}
.container-pag-videos .section{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; 
}
.container-pag-videos{
    padding: 8px;
}
.container-pag-videos iframe{
    width: 390px;
    height: 225px;
}