.rhap_progress-section{ display: none;}
.rhap_additional-controls{ display: none;}
.rhap_container{ 
    background: transparent;
    box-shadow: none;
    padding: 0;
}
.rhap_stacked .rhap_controls-section{ margin: 0;}
.rhap_volume-container{
    width: 100px;
}
main{
    position: fixed;
    flex-direction: row;
    align-items: center;
    background-color: #343434;
    top: 130px;
    padding: 3px 0;
    width: 100%;
    z-index: 1;
}
.container-play{
    display: flex;
}
.container-player{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}
.container-sociais{
    display: flex;
}
.box-sociais{
    margin-left: 10px;
}
.box-sociais a{ 
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: #343434;
    background-color: #fff;
}
.box-sociais a:hover{
    background-color: #009DE1;
    color: #fff;
}