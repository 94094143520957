.container-page-noticias{
    min-height: 500px;
}


.container-postagens{
    display: flex;
    flex: 1;
    justify-content: space-around;
    flex-direction: column;
    margin-top: 20px;
}
.container-postagens .section{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; 
}
.container-post{
    flex: 1 0 30%;   /* NEW */
    margin: 5px;     /* NEW */
    margin-bottom: 20px;
}
.container-post .box-img{
    height: auto;
    overflow: hidden;
    margin-bottom: 10px;
}
.container-post .box-img img{
    width: 100%;
}
.container-post span{ font-weight: bold; font-size: 0.8em; color:#404040;}
.container-post p{
    font-size: 0.9em;
    color:#404040;
    margin-top: 5px;
    white-space: inherit; 
    height: 5em; 
    text-overflow: ellipsis; 
    overflow: hidden;
}