*{
    padding: 0;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
}
body{
    background-color: #FEFEFE;
}
.container{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 17%;
}
.container-page{
    display: flex;
    padding: 10% 17%;
}

@media screen and (max-width: 1900px) {
    .slide-box{
        margin-top: 20px;
    }
}

@media screen and (max-width: 1600px) {
    .container{
        padding: 0 5% !important;
    }
    .container-page{
        padding: 9% 5% !important;
    }
    .iframe-video-destaque{
        max-width: 100%;
    }
    .iframe-video-destaque-list{
        max-width: 100%;
    }
    .slide-box{
        margin-top: 60px;
    }
}

@media screen and (max-width: 1300px) {
    .container{
        padding: 0 4% !important;
    }
    .container-page{
        padding: 11% 4% !important;
    }
}
@media screen and (max-width: 1100px) {
    .container{
        padding: 0 3% !important;
    }
    .container-page{
        padding: 15% 3% !important;
    }
    .box-modal .content{
        max-width: 60%!important;
    }
}

@media screen and (max-width: 820px) {
    .slide-box{
        margin-top: 80px;
    }
}

@media screen and (max-width: 930px) {
    .container-videos{
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .iframe-video-destaque{
        width: 660px;
        height: 370px;
    }
    .iframe-video-destaque-list{
        margin-bottom: 10px;
        width: 660px;
        height: 370px;
        margin-left: 0px;
    }
    .box-list-video{
        height: 420px!important;
        flex: none;
    }
}

@media screen and (max-width: 660px) {
    .slide-box{
        margin-top: 100px;
    }
}

@media screen and (max-width: 650px) {
    .iframe-video-destaque-list{
        margin-bottom: 0px;
        height: 280px;
    }
    .iframe-video-destaque{
        height: 280px;
    }
    .box-video{
        margin-top: 60px;
    }
    .footer .container{
        flex-direction: column;
    } 
    .section-sobre{
        justify-content: center;
    }
    .section-apio{
        align-items: center;
        margin-top: 50px;
    }
    .box-modal .content{
        max-width: 80%!important;
    }
}

@media screen and (max-width: 536px) {
    .slide-box{
        margin-top: 110px;
    }
}

@media screen and (max-width: 460px) {
    .iframe-video-destaque-list{
        height: 190px;
    }
    .iframe-video-destaque{
        height: 190px;
    }
    .box-video{
        margin-top: 60px;
    }
    .slide-box{
        margin-top: 120px;
    }
}

@media screen and (max-width: 580px) {
    .slide-box{
        margin-top: 140px;
    }
}




/* SCROLL */
/* Largura da barra de rolagem */
::-webkit-scrollbar {
    width: 7px;
    
}

/* Fundo da barra de rolagem */
::-webkit-scrollbar-track-piece {
    background-color: rgb(230, 230, 230);
}

/* Cor do indicador de rolagem */
::-webkit-scrollbar-thumb:vertical,
::-webkit-scrollbar-thumb:horizontal {
    background-color: #696969;
    border-radius: 5px;
}

/* Cor do indicador de rolagem - ao passar o mouse */
::-webkit-scrollbar-thumb:vertical:hover,
::-webkit-scrollbar-thumb:horizontal:hover {
    background-color: #000
}

.box-title{
    display: flex;
    flex-direction: row;
    border-bottom: solid 4px #009DE1;
    margin-bottom: 10px;
}
.box-title h2{
    background-color: #009DE1;
    padding: 8px 40px;
    font-size: 1.1em;
    color: #fff;
}