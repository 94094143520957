.container-page-home{
    display: flex;
    flex-direction: column;
}
.slide-box{
    display: flex;
    flex-direction: row;
    flex: 1;
    margin-top: 0;
}

.image-gallery{
    width: 100%;
    max-height: 350px;
}
.imagem-slide{
    width: 100%;
    max-height: 350px;
    outline: none;
}
.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
    width: 25px!important;
}

.container-videos{
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}
.box-video:first-child{
    flex: 3;
    margin-left: 0;
}
.box-video{
    flex: 1;
}
.box-list-video{
    height: 370px;
    flex: 3;
    overflow-y: scroll;
    overflow-x: hidden;
}
.iframe-video-destaque{
    width: 660px;
    height: 370px;
}

.iframe-video-destaque-list{
    margin-bottom: 10px;
    width: 580px;
    height: 325px;
    margin-left: 10px;
}

.container-postagens{
    display: flex;
    flex: 1;
    justify-content: space-around;
    flex-direction: column;
    margin-top: 20px;
}
.container-postagens .section{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; 
}
.container-post{
    flex: 1 0 30%;   /* NEW */
    margin: 5px;     /* NEW */
    margin-bottom: 20px;
    cursor: pointer;
}
.container-post .box-img{
    height: auto;
    overflow: hidden;
    margin-bottom: 10px;
}
.container-post .box-img img{
    width: 100%;
}
.container-post span{ font-weight: bold; font-size: 0.8em; color:#404040;}
.container-post p{
    font-size: 0.9em;
    color:#404040;
    margin-top: 5px;
    white-space: inherit; 
    height: 5em; 
    text-overflow: ellipsis; 
    overflow: hidden;
}